import React from 'react';

import * as styles from './index.module.css';

const SiteHeaderTagline = () => {
  return (
    <div className={styles['root']}>
      <span className={styles['tagline']}>
        スポーツ × 科学で指導をアップデートするメディア
      </span>
    </div>
  );
};

export default SiteHeaderTagline;
