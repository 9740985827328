import React from 'react';

import * as styles from './index.module.css';

const ObjectFitCoverImage = ({ src, alt, ...params }) => {
  return (
    <div className={styles['root']}>
      <img className={styles['img']} src={src} alt={alt} {...params} />
    </div>
  );
};

export default ObjectFitCoverImage;
