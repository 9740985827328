import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Location } from '@reach/router';

import useScrollPosition from '../../hooks/useScrollPosition';
import useElementSize from '../../hooks/useElementSize';

import GlobalNavigationTabs from '../../molecules/global-navigation/GlobalNavigationTabs';

import * as styles from './index.module.css';

const GlobalNavigation = ({ location }) => {
  const [rootClassName, setRootClassName] = useState(styles['root']);
  const [isPathnameChanged, setIsPathnameChanged] = useState(true);

  const isBrowser = useMemo(() => typeof window !== 'undefined', []);

  const { size } = useElementSize({
    element: isBrowser ? document.body : undefined,
  });

  const onWindowScroll = useCallback(
    ({ previousPosition, currentPosition }) => {
      if (size.width >= 576) {
        setRootClassName(styles['root']);
        return;
      }

      if (currentPosition.y < 86) {
        setRootClassName(styles['root']);
        return;
      }

      if (isPathnameChanged) {
        setRootClassName(styles['root']);
        setIsPathnameChanged(false);
        return;
      }

      const deltaY = currentPosition.y - previousPosition.y;
      if (deltaY > 15) {
        setRootClassName(`${styles['root']} ${styles['rootHidden']}`);
      } else if (deltaY < 0) {
        setRootClassName(styles['root']);
      }
    },
    [isPathnameChanged, size]
  );

  useScrollPosition({
    element: isBrowser ? document.body : undefined,
    handler: onWindowScroll,
  });

  useEffect(() => {
    setIsPathnameChanged(true);
  }, [location.pathname]);

  return (
    <div className={rootClassName}>
      <nav className={styles['navigation']}>
        <GlobalNavigationTabs />
      </nav>
    </div>
  );
};

export default () => (
  <Location>
    {({ location }) => <GlobalNavigation location={location} />}
  </Location>
);
