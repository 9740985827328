import React from 'react';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import SiteFooterLink from '../SiteFooterLink';

import * as styles from './index.module.css';

const SiteFooterLinkGroup = () => {
  return (
    <div>
      <ul className={styles['list']}>
        <li className={styles['listItem']}>
          <InlineBlockWrapper>
            <SiteFooterLink to="/about" label="About TORCH" />
          </InlineBlockWrapper>
        </li>
        <li className={styles['listItem']}>
          <InlineBlockWrapper>
            <SiteFooterLink to="/contact" label="お問い合わせ" />
          </InlineBlockWrapper>
        </li>
        <li className={styles['listItem']}>
          <InlineBlockWrapper>
            <SiteFooterLink to="/terms-of-service" label="利用規約" />
          </InlineBlockWrapper>
        </li>
        <li className={styles['listItem']}>
          <InlineBlockWrapper>
            <div className={styles['anchorWrapper']}>
              <a
                className={styles['anchor']}
                href="https://eu-phoria.jp/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                プライバシーポリシー
              </a>
            </div>
          </InlineBlockWrapper>
        </li>
        <li className={styles['listItem']}>
          <InlineBlockWrapper>
            <div className={styles['anchorWrapper']}>
              <a
                className={styles['anchor']}
                href="https://eu-phoria.jp"
                target="_blank"
                rel="noopener noreferrer"
              >
                運営会社
              </a>
            </div>
          </InlineBlockWrapper>
        </li>
      </ul>
    </div>
  );
};

export default SiteFooterLinkGroup;
