import { useState, useCallback, useLayoutEffect } from 'react';

const useElementSize = ({ element }) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const onResize = useCallback(() => {
    if (!element) {
      return;
    }

    const rect = element.getBoundingClientRect();

    setSize({ width: rect.width, height: rect.height });
  }, [element]);

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useLayoutEffect(() => {
    if (!element) {
      return;
    }

    const rect = element.getBoundingClientRect();

    setSize({ width: rect.width, height: rect.height });
  }, [element]);

  return { size };
};

export default useElementSize;
