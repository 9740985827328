import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import ObjectFitCoverImage from '../../common/ObjectFitCoverImage';

import * as styles from './index.module.css';

const SNSLinkGroup = () => {
  const { xIcon, facebookIcon } = useStaticQuery(
    graphql`
      query {
        xIcon: file(relativePath: { eq: "x-logo.svg" }) {
          publicURL
        }
        facebookIcon: file(relativePath: { eq: "f_logo_RGB-Blue_144.png" }) {
          publicURL
        }
      }
    `
  );

  return (
    <div>
      <ul className={styles['list']}>
        <li className={styles['listItem']}>
          <InlineBlockWrapper>
            <a
              className={styles['anchor']}
              href="https://twitter.com/torchsportsjp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles['iconWrapperX']}>
                <ObjectFitCoverImage
                  src={xIcon.publicURL}
                  alt="X link"
                  width={24}
                  height={24}
                  loading="lazy"
                />
              </div>
            </a>
          </InlineBlockWrapper>
        </li>
        <li>
          <InlineBlockWrapper>
            <a
              className={styles['anchor']}
              href="https://www.facebook.com/torchsportsjp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles['iconWrapper']}>
                <ObjectFitCoverImage
                  src={facebookIcon.publicURL}
                  alt="Facebook link"
                  width={32}
                  height={32}
                  loading="lazy"
                />
              </div>
            </a>
          </InlineBlockWrapper>
        </li>
      </ul>
    </div>
  );
};

export default SNSLinkGroup;
