import React from 'react';
import { Link } from 'gatsby';

import * as styles from './index.module.css';

const SiteHeaderTitle = () => {
  return (
    <div className={styles['root']}>
      <Link className={styles['link']} to="/">
        <span className="sr-only">TORCH</span>
      </Link>
    </div>
  );
};

export default SiteHeaderTitle;
