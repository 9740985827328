import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Location } from '@reach/router';

import { useAuth0 } from '../../contexts/Auth0Context';

import useScrollPosition from '../../hooks/useScrollPosition';
import useElementSize from '../../hooks/useElementSize';

import SiteHeaderTitle from '../../molecules/site-header/SiteHeaderTitle';
import SiteHeaderTagline from '../../molecules/site-header/SiteHeaderTagline';
import SiteHeaderMypageLink from '../../molecules/site-header/SiteHeaderMyPageLink';
import SiteHeaderLoginButton from '../../molecules/site-header/SiteHeaderLoginButton';
import SiteHeaderSignUpButton from '../../molecules/site-header/SiteHeaderSignUpButton';
import SiteHeaderSearchInput from '../../molecules/site-header/SiteHeaderSearchInput';

import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import * as styles from './index.module.css';

const SiteHeader = ({ location }) => {
  const { isAuthenticated } = useAuth0();

  const [rootClassName, setRootClassName] = useState(styles['root']);
  const [isPathnameChanged, setIsPathnameChanged] = useState(true);

  const isBrowser = useMemo(() => typeof window !== 'undefined', []);

  const { size } = useElementSize({
    element: isBrowser ? document.body : undefined,
  });

  const onWindowScroll = useCallback(
    ({ previousPosition, currentPosition }) => {
      if (size.width >= 576) {
        setRootClassName(styles['root']);
        return;
      }

      if (currentPosition.y < 132) {
        setRootClassName(styles['root']);
        return;
      }

      if (isPathnameChanged) {
        setRootClassName(styles['root']);
        setIsPathnameChanged(false);
        return;
      }

      const deltaY = currentPosition.y - previousPosition.y;
      if (deltaY > 15) {
        setRootClassName(`${styles['root']} ${styles['rootHidden']}`);
      } else if (deltaY < 0) {
        setRootClassName(styles['root']);
      }
    },
    [isPathnameChanged, size]
  );

  useScrollPosition({
    element: isBrowser ? document.body : undefined,
    handler: onWindowScroll,
  });

  useEffect(() => {
    setIsPathnameChanged(true);
  }, [location.pathname]);

  return (
    <header className={rootClassName}>
      <nav className={styles['navigation']}>
        <InlineBlockWrapper className={styles['titleWrapper']}>
          <SiteHeaderTitle />
        </InlineBlockWrapper>

        {(() => {
          if (isAuthenticated) {
            return (
              <InlineBlockWrapper className={styles['mypageWrapper']}>
                <SiteHeaderMypageLink />
              </InlineBlockWrapper>
            );
          }

          return (
            <div className={styles['buttonGroupArea']}>
              <InlineBlockWrapper>
                <SiteHeaderLoginButton />
              </InlineBlockWrapper>

              <div className={styles['buttonGroupDivider']} />

              <InlineBlockWrapper>
                <SiteHeaderSignUpButton />
              </InlineBlockWrapper>
            </div>
          );
        })()}

        <div className={styles['taglineArea']}>
          <InlineBlockWrapper>
            <SiteHeaderTagline />
          </InlineBlockWrapper>
        </div>

        <div className={styles['searchInputWrapper']}>
          <SiteHeaderSearchInput />
        </div>
      </nav>
    </header>
  );
};

export default () => (
  <Location>{({ location }) => <SiteHeader location={location} />}</Location>
);
