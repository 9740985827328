import React from 'react';
import { Link } from 'gatsby';

import * as styles from './index.module.css';

const SiteHeaderMyPageLink = () => {
  return (
    <div className={styles['root']}>
      <Link className={styles['link']} to="/mypage">
        マイページ
      </Link>
    </div>
  );
};

export default SiteHeaderMyPageLink;
