import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({
  path,
  title,
  lang,
  description,
  ogImage,
  ogLocale,
  featuredType,
}) => {
  const { site, defualtOgImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            fbAppId
            twiiterSite
          }
        }
        defualtOgImage: file(relativePath: { eq: "ogp-image.jpg" }) {
          publicURL
        }
      }
    `
  );

  const isIndex = title === 'TORCH';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        isIndex
          ? '%s | スポーツ × 科学で指導をアップデートするメディア'
          : `%s | ${site.siteMetadata.title}`
      }
      meta={[
        {
          name: 'description',
          content: description || site.siteMetadata.description,
        },
        {
          property: 'og:title',
          content: featuredType !== 'advertorial' ? title : `${title} [PR]`,
        },
        {
          property: 'og:type',
          content: isIndex ? 'website' : 'article',
        },
        {
          property: 'og:image',
          content: ogImage
            ? `https:${ogImage}`
            : `${site.siteMetadata.siteUrl}${defualtOgImage.publicURL}`,
        },
        {
          property: 'og:url',
          content: isIndex
            ? site.siteMetadata.siteUrl
            : `${site.siteMetadata.siteUrl}${path}`,
        },
        {
          property: 'og:description',
          content: description || site.siteMetadata.description,
        },
        {
          property: 'og:locale',
          content: ogLocale,
        },
        !isIndex && {
          property: 'og:site_name',
          content: `${site.siteMetadata.title} | スポーツ × 科学で指導をアップデートするメディア`,
        },
        {
          property: 'fb:app_id',
          content: site.siteMetadata.fbAppId,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata.twiiterSite,
        },
      ].filter(Boolean)}
    />
  );
};

SEO.defaultProps = {
  lang: 'ja',
  description: '',
  ogLocale: 'ja_JP',
  featuredType: undefined,
};

SEO.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  lang: PropTypes.string,
  description: PropTypes.string,
  ogImage: PropTypes.string,
  ogLocale: PropTypes.string,
  featuredType: PropTypes.string,
};

export default SEO;
