import React, { useCallback } from 'react';
import { Location } from '@reach/router';

import { useAuth0 } from '../../../contexts/Auth0Context';

import * as styles from './index.module.css';

const SiteHeaderLoginButton = ({ location }) => {
  const { isAuth0Ready, loginWithRedirect } = useAuth0();

  const onClick = useCallback(() => {
    const appState = {
      targetUrl: location.href.replace(location.origin, ''),
    };
    loginWithRedirect(appState);
  }, [location, loginWithRedirect]);

  return (
    <div className={styles['root']}>
      <button
        className={styles['button']}
        onClick={onClick}
        disabled={!isAuth0Ready}
      >
        ログイン
      </button>
    </div>
  );
};

export default () => (
  <Location>
    {({ location }) => <SiteHeaderLoginButton location={location} />}
  </Location>
);
