import React from 'react';
import { Link } from 'gatsby';

import * as styles from './index.module.css';

const GlobalNavigationTab = ({ to, label, isPartiallyActive, icon: Icon }) => {
  return (
    <div className={styles['root']}>
      <Link
        className={styles['link']}
        activeClassName={styles['linkActive']}
        partiallyActive={isPartiallyActive}
        to={to}
      >
        <div className={styles['itemInner']}>
          <div className={styles['iconWrapper']}>
            <Icon />
          </div>
          <div>
            <span className={styles['label']}>{label}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default GlobalNavigationTab;
