import React from 'react';

import GlobalNavigationTab from '../GlobalNavigationTab';
import { ReactComponent as HomeIcon } from '../../../images/icon-home.svg';
import { ReactComponent as StarIcon } from '../../../images/icon-star.svg';
import { ReactComponent as ListIcon } from '../../../images/icon-list.svg';
import { ReactComponent as FlagIcon } from '../../../images/icon-flag.svg';
import { ReactComponent as BookIcon } from '../../../images/icon-book.svg';

import * as styles from './index.module.css';

const GlobalNavigationTabs = () => {
  return (
    <div>
      <ul className={styles['list']}>
        <li>
          <GlobalNavigationTab to="/" label="ホーム" icon={HomeIcon} />
        </li>
        <li className={styles['listItemSpacer']} />
        <li>
          <GlobalNavigationTab
            to="/series"
            label="シリーズ"
            isPartiallyActive={true}
            icon={StarIcon}
          />
        </li>
        <li className={styles['listItemSpacer']} />
        <li>
          <GlobalNavigationTab
            to="/category"
            label="カテゴリー"
            isPartiallyActive={true}
            icon={ListIcon}
          />
        </li>
        <li className={styles['listItemSpacer']} />
        <li>
          <GlobalNavigationTab
            to="/event"
            label="イベント"
            isPartiallyActive={true}
            icon={FlagIcon}
          />
        </li>
        <li className={styles['listItemSpacer']} />
        <li>
          <GlobalNavigationTab
            to="/term"
            label="用語集"
            isPartiallyActive={true}
            icon={BookIcon}
          />
        </li>
      </ul>
    </div>
  );
};

export default GlobalNavigationTabs;
