import React, { useMemo } from 'react';

import ContainerDefault from '../../components/ContainerDefault';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import SiteFooterLinkGroup from '../../molecules/site-footer/SiteFooterLinkGroup';
import SNSLinkGroup from '../../molecules/site-footer/SNSLinkGroup';

import * as styles from './index.module.css';

const SiteFooter = () => {
  const year = useMemo(() => {
    const now = new Date();
    return now.getFullYear();
  }, []);

  return (
    <footer className={styles['root']}>
      <ContainerDefault>
        <div className={styles['footerAndSnsLinkGroupWrapper']}>
          <div className={styles['footerLinkGroupWrapper']}>
            <SiteFooterLinkGroup />
          </div>
          <div className={styles['snsLinkGroupWrapper']}>
            <SNSLinkGroup />
          </div>
        </div>

        <InlineBlockWrapper className={styles['copyrightWrapper']}>
          <span className={styles['copyright']}>
            © {year} Euphoria Co., Ltd.
          </span>
        </InlineBlockWrapper>
      </ContainerDefault>
    </footer>
  );
};

export default SiteFooter;
