import { useMemo, useRef, useCallback, useLayoutEffect } from 'react';

const useScrollPosition = ({ element, handler }) => {
  const isBrowser = useMemo(() => typeof window !== 'undefined', []);

  const getScrollPosition = useCallback(
    ({ element }) => {
      if (!isBrowser || !element) {
        return { x: 0, y: 0 };
      }

      const rect = element.getBoundingClientRect();

      return { x: 0 - rect.left, y: 0 - rect.top };
    },
    [isBrowser]
  );

  const position = useRef({ x: 0, y: 0 });

  const onScroll = useCallback(() => {
    const currentPosition = getScrollPosition({ element });

    if (handler) {
      handler({
        previousPosition: position.current,
        currentPosition,
      });
    }

    position.current = currentPosition;
  }, [element, handler, getScrollPosition]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);
};

export default useScrollPosition;
