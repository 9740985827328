import React, { useMemo, useCallback, useEffect } from 'react';
import { useLocation } from '@reach/router';
import * as styles from './index.module.css';
import './index.css';

const SiteHeaderSearchInput = () => {
  const isBrowser = useMemo(() => typeof window !== 'undefined', []);

  const { pathname } = useLocation();

  const onWebStarting = useCallback(
    (_, query) => {
      if (!window.dataLayer) {
        return;
      }

      const pathnameWithQuery = `${pathname}?q=${query}`;
      window.dataLayer.push({
        event: 'pageview',
        GA_PagePath: pathnameWithQuery,
      });
    },
    [pathname]
  );

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    window.__gcse = {
      searchCallbacks: {
        web: {
          starting: onWebStarting,
        },
      },
    };

    const src = 'https://cse.google.com/cse.js?cx=456cf06fc897fcb92';

    const currentScripts = Array.from(document.getElementsByTagName('script'));

    const oldScript = currentScripts.find(cs => cs.src === src);

    if (oldScript) {
      oldScript.remove();
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    document.body.appendChild(script);
  }, [isBrowser, onWebStarting]);

  return (
    <div className={styles['root']}>
      <div className="gcse-search" />
    </div>
  );
};

export default SiteHeaderSearchInput;
